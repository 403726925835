<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      do-route-filters
      expanding-row
      headers-filterable
      no-meta-check
      prune-search-filters
      resource="products"
      route-base="/reports/statistics/products"
      show-filter
      sortable
      :buttons="computedButtons"
      :custom-resource="customResource()"
      :expand-row-if="expandRowIf"
      :headers-config="headersConfig"
      :headers-default-hide="headersDefaultHide"
      :headers="headers"
      :locale="locale"
      :resource-query="resourceQuery"
      :row-key="getRowKey"
      :search-filters="filtersList"
      :show-operations="false"
      :summary-headers="summaryHeaders"
      :summary="summary"
      :transform-fetched-data="transformData"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
      @search-filter-submit="
        $ampli.eventWithBaseProps('statisticsFiltersSearchButtonClick')
      "
    >
      <template #expanding-row="props" type="expand">
        <expanding-row
          :headers="headers"
          :resource-query="resourceQuery"
          :currency="props.row.currency"
          :row="props.row"
        />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pRetry from 'p-retry'
import safeGet from 'just-safe-get'
import ExpandingRow from './expanding-row'
import { mapGetters } from 'vuex'
import qs from 'qs'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'
import {
  applyFiltersBeforeRouteEnter,
  formatDateRange,
  getDateTimeFormat,
  getRangeFor
} from '@/utils/date'
import { useExportsStore } from '@/store/exports'

function parseStaffName(staff) {
  return `${staff.staff_number || ''} ${
    staff.staff_number && (staff.firstname || staff.lastname) ? '-' : ''
  } ${`${staff.firstname || ''} ${staff.lastname || ''}`.trim() || ''}`.trim()
}

export default {
  metaInfo() {
    return {
      title: this.$t('pages.reports.statistics.products.products')
    }
  },
  components: {
    ExpandingRow
  },
  beforeRouteEnter: (to, _, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, _, next) {
    this.$emit('route-filter', safeGet(qs.parse(to.query), 'filter') || {})
    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.reports.statistics.products'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  },
  data() {
    return {
      isLegacy: true,
      loadingExport: false,
      filters: {},
      product: undefined,
      width: 500,
      headersDefaultHide: ['manufacturer', 'supplier', 'brand'],
      headers: [
        {
          label: this.$t('pages.reports.statistics.products.product_number'),
          field: 'product_number',
          fallback: '-',
          minWidth: 200
        },
        {
          label: this.$t('common.headers.name.title'),
          field: 'name',
          truncate: true,
          fallback: '-',
          minWidth: 180
        },
        {
          label: this.$t('pages.reports.statistics.products.product_group'),
          field: 'product_group_name',
          truncate: true,
          fallback: '-',
          minWidth: 180
        },
        {
          field: '_attributes_names',
          label: this.$t('pages.reports.statistics.products.attributes'),
          fallback: '-',
          align: 'right',
          minWidth: 150,
          truncate: true
        },
        {
          label: this.$t('pages.reports.statistics.products.qty'),
          field: 'qty',
          truncate: true,
          fallback: '-',
          minWidth: 120,
          align: 'right',
          sortType: 'number'
        },
        {
          label: this.$t('pages.reports.statistics.all.revenue'),
          field: 'revenue',
          align: 'right',
          minWidth: 140,
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.revenue)) {
              return this.$formatCurrency(row.revenue, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.all.net_revenue'),
          field: 'net_revenue',
          align: 'right',
          minWidth: 140,
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.net_revenue)) {
              return this.$formatCurrency(row.net_revenue, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.products.return_rate'),
          field: 'return_rate',
          type: 'number',
          minWidth: 140,
          truncate: true,
          align: 'right',
          sortType: 'number'
        },
        {
          label: this.$t('pages.reports.statistics.products.cancellation'),
          field: 'cancellation',
          fallback: '-',
          truncate: true,
          minWidth: 140,
          align: 'right',
          sortType: 'number'
        },
        {
          label: this.$t('pages.reports.statistics.products.top_saleman'),
          field: 'sales_rep',
          fallback: '-',
          minWidth: 200,
          align: 'right',
          truncate: true,
          formatter: (row) => {
            if (!row.top_sale_rep) return '-'
            const staff = {
              firstname: row.firstname,
              lastname: row.lastname,
              staff_number: row.staff_number
            }
            if (!staff.firstname && !staff.lastname) return '-'
            return parseStaffName(staff) || '-'
          }
        },
        {
          label: this.$t('pages.reports.statistics.products.total_profit'),
          field: 'total_profit',
          align: 'right',
          minWidth: 140,
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.total_profit)) {
              return this.$formatCurrency(row.total_profit, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.products.total_discount'),
          field: 'total_discount',
          align: 'right',
          minWidth: 150,
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.total_discount)) {
              return this.$formatCurrency(row.total_discount, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.products.line_cancellation'),
          field: 'line_cancellation',
          fallback: '-',
          truncate: true,
          minWidth: 160,
          align: 'right',
          sortType: 'number'
        },
        {
          label: this.$t('pages.reports.statistics.products.barcode'),
          field: 'barcode',
          truncate: true,
          align: 'right',
          fallback: '-',
          minWidth: 180
        },
        {
          label: this.$t('pages.reports.statistics.products.manufacturer'),
          field: 'manufacturer',
          truncate: true,
          align: 'right',
          fallback: '-',
          minWidth: 180
        },
        {
          label: this.$t('pages.reports.statistics.products.supplier'),
          field: 'supplier',
          truncate: true,
          align: 'right',
          fallback: '-',
          minWidth: 180
        },
        {
          label: this.$t('pages.reports.statistics.products.brand'),
          field: 'brand',
          truncate: true,
          align: 'right',
          fallback: '-',
          minWidth: 180
        }
      ],
      revenueOptions: [
        {
          label: this.$t('pages.reports.statistics.all.revenue'),
          value: 'revenue',
          type: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.all.net_revenue'),
          value: 'net_revenue',
          type: 'currency'
        }
      ],
      summary: null,
      buttons: [
        {
          type: 'custom_export',
          scopes: ['reports_statistics:products:export'],
          label: this.$t('common.interactions.buttons.export'),
          clickHandler: () => {
            this.handleExport()
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      branchNumber: 'Config/getCurrentBranchNumber',
      locale: 'Config/getLocale',
      defaultDateSelected: 'Config/getDefaultDateSelected',
      currentLocation: 'Config/getCurrentLocation'
    }),
    filtersList() {
      return [
        {
          name: 'custom_id',
          type: 'remote-search-select',
          label: this.$t('pages.reports.statistics.products.product'),
          placeholder: this.$t('common.inputs.placeholders.search'),
          resource: 'products',
          optionsValue: 'custom_id',
          computedFields: ['custom_id', 'name'],
          fetchHandler: 'getAll',
          modifyQuery: (q) => ({
            q,
            limit: 50,
            deleted: false,
            original_product: true
          })
        },
        {
          name: 'product_group',
          type: 'remote-search-select',
          resource: 'productGroups',
          fetchHandler: 'getAll',
          computedFields: ['product_group_id', 'name'],
          label: this.$t('pages.reports.statistics.products.product_group'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          modifyQuery: (q) => ({ q, limit: 50, deleted: false })
        },
        {
          name: 'register_number',
          type: 'select',
          label: this.$t('pages.reports.statistics.products.register'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          options:
            this.resources.registers &&
            this.resources.registers
              .filter((r) => Number.isFinite(r.register_number)) // needed to prevent odd default behavior
              .map((r) => ({
                value: r.register_number,
                label: this.$formatRegister(r),
                key: r.id
              })),
          filterable: true
        },
        {
          name: 'staff_number',
          type: 'remote-search-select',
          label: this.$t('pages.reports.statistics.products.staff'),
          resource: 'staff',
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          computeName: (staff) =>
            this.$formatStaff(staff, ['staff_number', 'fullName'], ' - '),
          modifyQuery: (q) => ({
            q,
            fields: ['staff_number', 'lastname', 'firstname'],
            deleted: false
          })
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          prop: ['start', 'end'],
          type: 'daterange',
          dateTimeMode: true,
          label: this.$t('pages.transactions.all.filters.date.label'),
          closable: false,
          autoClose: false,
          formatValue: (value) => formatDateRange(value, getDateTimeFormat()),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    parsedDate() {
      return this.parsedQuery.date || {}
    },
    date() {
      return {
        start: new Date(this.parsedDate.start),
        end: new Date(this.parsedDate.end),
        showDateText: true
      }
    },
    resourceQuery() {
      return {
        legacy: this.isLegacy,
        branch_number: this.branchNumber || undefined,
        branch_group: this.parsedQuery.branch_group || undefined,
        end: this.parsedDate.end || undefined,
        start: this.parsedDate.start || undefined,
        custom_id: this.parsedQuery.custom_id || undefined,
        product_group: this.parsedQuery.product_group || undefined,
        register_number: this.parsedQuery.register_number || undefined,
        staff_number: this.parsedQuery.staff_number || undefined
      }
    },
    summaryHeaders() {
      return this.headers.filter((item) =>
        ['qty', 'return_rate', 'line_cancellation', 'cancellation'].includes(
          item.field
        )
      )
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  watch: {
    'parsedQuery.custom_id': {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.fetchProduct(newValue)
        }
      }
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    expandRowIf(row) {
      return row.has_variants
    },
    customResource() {
      const self = this
      return {
        getAll(params) {
          return pRetry(
            () =>
              new Promise((resolve, reject) => {
                th.analytics({ timeout: 90000 })
                  .getReportsProducts(self.resourceQuery)
                  .then(({ data }) => {
                    const list =
                      data &&
                      data.find(
                        (d) => safeGet(d, 'metric.job') === 'reports_products'
                      )
                    const summary =
                      data &&
                      data.find(
                        (d) =>
                          safeGet(d, 'metric.job') ===
                          'reports_products.summary'
                      )

                    if (summary && summary.values) {
                      if (Array.isArray(summary.values)) {
                        self.summary = summary.values
                      } else {
                        self.summary = [summary.values]
                      }
                    }

                    if (list && list.values) {
                      resolve({
                        data: list.values
                      })
                    }

                    resolve({
                      data: []
                    })
                  })
                  .catch((error) => reject(error))
              }),
            {
              retries: 5
            }
          )
        }
      }
    },
    handleLoadingError() {},
    transformData(data) {
      data.forEach((item) => {
        const attributeKeys = item?.attributes && Object.keys(item.attributes)
        if (attributeKeys?.length) {
          item._attributes_names = attributeKeys
            .reduce((r, key) => [...r, item.attributes[key]], [])
            .join(' | ')
        }
      })
      return data
    },

    updateTableHeaders(type) {
      const option = this.revenueOptions.find((item) => item.value === type)
      const updatedHeader = [...this.tableHeaders]
      updatedHeader.splice(7, 1, option)
      this.tableHeaders = updatedHeader
      this.setVisibleTableData([...this.visibleTableData])
    },
    async handleExport() {
      this.$ampli.eventWithBaseProps('statisticsExportButtonClick')

      const query = {
        ...this.resourceQuery,
        format: 'csv'
      }

      try {
        const {
          data
        } = await th
          .analyticsHandlers()
          .analytics.reports.AnalyticsReportsProducts.getAll(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.reports.statistics.products.reports.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlers',
              path: 'analytics.reports.AnalyticsReportsProducts',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.reports.statistics.products.reports.title')
          })
        })
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.$refs.table.refresh()
      })
    },
    getRowKey(row) {
      // products report can have multiple rows of the same product for different currencies,
      // we'll differentiate between them in the DOM by combine the product id with the product currency.
      return `${row.id}-${row.currency}`
    },
    async fetchProduct(product) {
      try {
        const { data } = await this.$resourceFetch({
          resource: 'products',
          query: { custom_id: product }
        })
        this.product = data?.[0]
      } catch (error) {
        this.$logException(error, {
          trackError: false
        })
      }
    }
  }
}
</script>

<style scoped>
.product-table {
  height: 100%;
  width: 100%;
}
.statistics-resource-table :deep(.el-table :deep(.el-table__expanded-cell)) {
  background: #e9eaeb;
  padding: 0px;
  border-left: 3px solid rgb(49, 161, 243);
}

.statistics-resource-table
  :deep(.el-table)
  :deep(.el-table__row.expanded)
  td:first-child {
  border-left: 3px solid rgb(49, 161, 243);
}

.statistics-resource-table :deep(.el-table :deep(td)) {
  padding: 10px 0px !important;
}

.statistics-resource-table :deep(.el-table :deep(th .cell)) {
  white-space: unset;
}

.popover {
  margin-right: 10px;
}

.statistics-resource-table :deep(.el-table .el-table__expanded-cell) {
  margin: 0 !important;
  padding: 0;
}
</style>
