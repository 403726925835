<template>
  <el-table
    v-loading="loading"
    class="expandle-product-table"
    :show-header="true"
    :data="tableData"
    :max-height="800"
  >
    <el-table-column width="48px" />
    <el-table-column
      v-for="header in headers.map((item) => ({ ...item }))"
      :key="header.field"
      show-overflow-tooltip
      :width="header.width"
      :label="header.label"
      :prop="header.field"
      :align="header.align"
      :formatter="header.formatter"
    />
  </el-table>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pRetry from 'p-retry'

export default {
  props: {
    row: {
      type: Object,
      required: true
    },
    resourceQuery: {
      type: Object,
      required: false,
      default: () => ({})
    },
    headers: {
      type: Array,
      required: true
    },
    currency: {
      type: String,
      required: false,
      default: undefined
    }
    // getQuery: {
    //   type: Function,
    //   required: true
    // },
    // getCellValue: {
    //   type: Function,
    //   required: true
    // }
  },
  data() {
    return {
      tableData: [],
      loading: false,
      tableHeaders: [
        {
          label: this.$t('pages.reports.statistics.products.product_number'),
          value: 'product_number'
        },
        {
          label: this.$t('common.headers.type.title'),
          value: 'name'
        },
        {
          label: this.$t('pages.reports.statistics.products.qty'),
          value: 'qty'
        },
        {
          label: this.$t('pages.reports.statistics.products.returned'),
          value: 'return_rate'
        },
        {
          label: this.$t('pages.reports.statistics.products.line_cancellation'),
          value: 'line_cancellation'
        },
        {
          label: this.$t('pages.reports.statistics.products.cancellation'),
          value: 'cancellation'
        },
        {
          label: this.$t('pages.reports.statistics.products.salerep'),
          value: 'sales_rep'
        },
        {
          label: this.$t('pages.reports.statistics.all.revenue'),
          value: 'revenue',
          type: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.all.discount'),
          value: 'discount_amount',
          type: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.products.total_profit'),
          value: 'total_profit'
        }
      ],
      revenueOptions: [
        {
          label: this.$t('pages.reports.statistics.all.revenue'),
          value: 'revenue',
          type: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.all.net_revenue'),
          value: 'net_revenue',
          type: 'currency'
        }
      ]
    }
  },
  mounted() {
    this.productChildrenTable()
  },
  methods: {
    async productChildrenTable() {
      const query = {
        ...this.resourceQuery,
        ...{ currency: this.currency || this.row.currency || 'EUR' }
      }

      this.loading = true
      try {
        const resp = await pRetry(
          () => th.analytics().getProductsChildren(this.row.id, query),
          { retries: 5 }
        )
        this.tableData = resp.data[0].values
      } catch (err) {
        this.tableData = []
      } finally {
        this.loading = false
      }
    },
    setExpandColumn({ row, column, rowIndex, columnIndex }) {
      // This will make a row type to be expand if has_variants is true
      if (!row.has_variants) {
        if (columnIndex === 1) {
          return [1, 2]
        } else if (columnIndex === 0) {
          return [0, 0]
        }
      }
    }
  }
}
</script>

<style scoped>
.el-table,
.el-table :deep(tr),
.el-table::before {
  background: none;
  width: 100%;
}

.el-table :deep(td) {
  border-bottom: none;
}

.el-table__body {
  width: 100%;
}

.expandle-product-table :deep(.el-table__header tr) {
  display: none;
}
</style>
